import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';



export const triggerUploadCompletion = async (filename: string, audioname: string, lastchunkid: number) => {

  try {
    console.log({
      filename,
      audioname,
      lastchunkid,
    });
    const response = await axios.post(`/api/apps/complete-upload/`, {
      filename: filename, audioname: audioname, lastchunkid: lastchunkid
    });

    console.log('Audio-Processing abgeschlossen:', response.data);
    alert(`Audio-Datei wurde erfolgreich verarbeitet: ${response.data.final_file}`);
  } catch (error) {
    console.error('Fehler beim Abschließen der Audioverarbeitung:', error);
    alert('Fehler beim Abschließen der Verarbeitung. Details in der Konsole.');
  }
};



// Timestamp-Funktion
export const generateAudioTimestamp = (extension: string = ''): string => {
  return `audio_${new Date().toISOString().replace(/[:.]/g, '-')}${extension}`;
};

const AudioRecorderTab: React.FC= ({}) => {
  const sessionIdRef = useRef<string>(''); // Ref für session_id

  const accumulatedBufferRef = useRef<Float32Array[]>([]); // Puffer für Audioprozess
  const currentChunkDurationRef = useRef<number>(0); // Dauer der aktuellen Chunks in Sekunden
  const chunkIdRef = useRef<number>(1); // ID des Chunks
  const monitoringRef = useRef<boolean>(false); // Flag zur Steuerung der Lautstärkeüberwachung

  const [recordingState, setRecordingState] = useState<string>('inactive'); // inactive, recording, paused
  const [recordingTime, setRecordingTime] = useState(0); // in seconds
  const [audioName, setAudioName] = useState<string>('');
  const [generatedFilename, setGeneratedFilename] = useState<string>('empty');

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const processorRef = useRef<ScriptProcessorNode | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const volumeBarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') audioContextRef.current.close();
    };
  }, []);

  const startRecording = async () => {
    setRecordingTime(0);

    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);

    sessionIdRef.current = generateAudioTimestamp();

    const filename = `${sessionIdRef.current}.wav`;
    setGeneratedFilename(filename);
    accumulatedBufferRef.current = []; // Leerer Puffer
    currentChunkDurationRef.current = 0; // Setze Chunk-Dauer zurück
    chunkIdRef.current = 1; // Setze Chunk-ID zurück
    setRecordingTime(0); // Setze die Aufnahmezeit zurück

    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = audioStream;

      const audioContext = new AudioContext();
      audioContextRef.current = audioContext;

      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      analyserRef.current = analyser;
      const dataArray = new Uint8Array(analyser.frequencyBinCount); // Erstelle ein neues Datenarray
      dataArrayRef.current = dataArray;

      const source = audioContext.createMediaStreamSource(audioStream);
      const processor = audioContext.createScriptProcessor(16384, 1, 1);
      processorRef.current = processor;

      source.connect(analyser);
      analyser.connect(processor);
      processor.connect(audioContext.destination);



      processor.onaudioprocess = (event) => {
        const audioData = event.inputBuffer.getChannelData(0);
        accumulatedBufferRef.current.push(audioData);
        currentChunkDurationRef.current += 16384 / audioContext.sampleRate; // Aktualisiere die Dauer



        if (currentChunkDurationRef.current >= 60) {
          const combinedBuffer = mergeBuffers(accumulatedBufferRef.current, audioContext.sampleRate);
          const wavBlob = convertToWav(combinedBuffer, audioContext.sampleRate);
          
          sendAudioChunkToServer(wavBlob, filename, sessionIdRef.current, chunkIdRef.current);
          accumulatedBufferRef.current = []; // Zurücksetzen
          currentChunkDurationRef.current = 0;
          chunkIdRef.current++;
        }
      };

      setRecordingState('recording');
      monitoringRef.current=true;
      startVolumeMonitoring();
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Fehler beim Starten der Aufnahme.');
    }
  };

  function mergeBuffers(buffers: Float32Array[], sampleRate: number): Float32Array {
    if (buffers.length === 0) {
      throw new Error("Buffers array is empty");
    }

    const totalLength = buffers.reduce((acc, buffer) => acc + buffer.length, 0);
    const mergedBuffer = new Float32Array(totalLength);
    let offset = 0;
    buffers.forEach((buffer, index) => {
      if (buffer.length === 0) {
        console.warn(`Buffer at index ${index} is empty`);
      }
      //console.log(`Merging buffer ${index}: Offset ${offset}, Length ${buffer.length}`);

      mergedBuffer.set(buffer, offset);
      offset += buffer.length;
    });
    //console.log(`Total merged buffer length: ${totalLength}`);

    return mergedBuffer;
  }

  const pauseRecording = () => {
    if (processorRef.current) {
      processorRef.current.onaudioprocess = null; // Verarbeitung stoppen
      processorRef.current.disconnect();         // Trenne den Prozessor
    }

    if (streamRef.current) {
      streamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = false; // Tracks deaktivieren
      });
    }

    // Der Buffer bleibt intakt
    setRecordingState('paused');
    monitoringRef.current=false;

    if (timerRef.current) clearInterval(timerRef.current);
    alert('Aufnahme pausiert.');
  };

  const resumeRecording = () => {
    if (processorRef.current && audioContextRef.current) {
      const audioContext = audioContextRef.current;
      const source = audioContext.createMediaStreamSource(streamRef.current!);
      source.connect(processorRef.current);

      streamRef.current!.getAudioTracks().forEach((track) => {
        track.enabled = true; // Tracks reaktivieren
      });

      processorRef.current.onaudioprocess = (event) => {
        const audioData = event.inputBuffer.getChannelData(0);
        accumulatedBufferRef.current.push(audioData); // Buffer fortsetzen
        currentChunkDurationRef.current += 16384 / audioContext.sampleRate;

        if (currentChunkDurationRef.current >= 60) {
          const combinedBuffer = mergeBuffers(accumulatedBufferRef.current, audioContext.sampleRate);
          const wavBlob = convertToWav(combinedBuffer, audioContext.sampleRate);
          sendAudioChunkToServer(wavBlob, generatedFilename, sessionIdRef.current, chunkIdRef.current);
          accumulatedBufferRef.current = [];
          currentChunkDurationRef.current = 0;
          chunkIdRef.current++;
        }
      };

      setRecordingState('recording');
      monitoringRef.current=true;
      startVolumeMonitoring();


      timerRef.current = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
      alert('Aufnahme fortgesetzt.');
    }
  };

  const stopRecording = () => {
    if (currentChunkDurationRef.current >= 3) {
      const combinedBuffer = mergeBuffers(accumulatedBufferRef.current, audioContextRef.current!.sampleRate);
      const wavBlob = convertToWav(combinedBuffer, audioContextRef.current!.sampleRate);
      sendAudioChunkToServer(wavBlob, generatedFilename, sessionIdRef.current, chunkIdRef.current);
  
      // Nach dem Senden den Buffer zurücksetzen
      
    }
  
    if (processorRef.current) {
      processorRef.current.onaudioprocess = null; // Beende das AudioProcessing
      processorRef.current.disconnect();         // Trenne den Prozessor
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop()); // Stoppe den Stream
    }

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close(); // Schließe den AudioContext
    }

    if (timerRef.current) {
      clearInterval(timerRef.current); // Beende den Timer
    }
    

    try {
      triggerUploadCompletion(generatedFilename, audioName, chunkIdRef.current);
    } catch (error) {
      console.error('Fehler beim automatischen Abschluss der Verarbeitung:', error);
      alert('Fehler beim automatischen Abschluss der Verarbeitung. Bitte versuchen Sie es manuell.');
    }
    
    // Zurücksetzen der sessionspezifischen Daten
    sessionIdRef.current = '';
    accumulatedBufferRef.current = [];
    currentChunkDurationRef.current = 0;
    chunkIdRef.current = 1;
    setRecordingState('inactive');
    monitoringRef.current=false;

    setRecordingTime(0);
    alert('Aufnahme gestoppt.');
   
  };

  const startVolumeMonitoring = () => {
  const monitor = () => {
    if (analyserRef.current && dataArrayRef.current) {
      // Hole die Daten aus dem Analyser
      analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

      // Berechne die Lautstärke
      const sum = dataArrayRef.current.reduce((acc, val) => acc + Math.pow(val - 128, 2), 0);
      const rms = Math.sqrt(sum / dataArrayRef.current.length);
      const volume = Math.min(100, (rms / 16) * 100);
      
      // Aktualisiere die Anzeige
      if (volumeBarRef.current) {
        volumeBarRef.current.style.width = `${volume}%`;
        volumeBarRef.current.style.backgroundColor = volume > 75 ? 'red' : 'green';
      }
    }

    // Überwachung fortsetzen, wenn die Aufnahme aktiv ist
    if (monitoringRef.current) {
      requestAnimationFrame(monitor);
    }
  };

  monitor(); // Starte den Monitor
};

  const convertToWav = (audioData: Float32Array, sampleRate: number): Blob => {
    const buffer = new ArrayBuffer(44 + audioData.length * 2);
    const view = new DataView(buffer);
    console.log(`Längeer der audioDaten beim konvertieren: ${audioData.length}`);

    const writeString = (view: DataView, offset: number, string: string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + audioData.length * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, audioData.length * 2, true);

    let offset = 44;
    for (let i = 0; i < audioData.length; i++) {
      const sample = Math.max(-1, Math.min(1, audioData[i]));
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
      offset += 2;
    }

    return new Blob([view], { type: 'audio/wav' });
  };

  const sendAudioChunkToServer = async (chunk: Blob, filename: string, session_id: string, chunk_id: number) => {
    const formData = new FormData();
    formData.append('audio', chunk, `chunk_${chunk_id}.wav`);
    formData.append('filename', filename);
    formData.append('session_id', session_id);
    formData.append('chunk_id', chunk_id.toString());

    try {
      await axios.post('/api/apps/stream-audio/', formData);
      console.log(`Chunk ${chunk_id} gesendet.`);
    } catch (error) {
      console.error('Fehler beim Senden eines Audio-Chunks:', error);
    }
  };

  return (
    <div>
      <Typography variant="h6">Audio aufnehmen</Typography>
      <TextField
        label="Dateiname"
        fullWidth
        variant="outlined"
        value={audioName}
        onChange={(e) => setAudioName(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Typography variant="h6">Aufnahmezeit: {recordingTime}s</Typography>
      {recordingState === "recording" && (
        <div style={{ background: "#ddd", height: "20px", borderRadius: "5px" }}>
          <div
            ref={volumeBarRef}
            style={{
              height: "100%",
              width: "0%",
              background: "green",
              transition: "width 0.1s linear",
            }}
          />
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        {recordingState === "inactive" && (
          <Button
            onClick={startRecording}
            color="primary"
            variant="contained"
            disabled={!audioName.trim()}
          >
            Aufnahme starten
          </Button>
        )}
        {recordingState === "recording" && (
          <>
            <Button onClick={pauseRecording} variant="contained" style={{ marginRight: "10px" }}>
              Pausieren
            </Button>
            <Button onClick={stopRecording} color="secondary" variant="contained">
              Stoppen
            </Button>
          </>
        )}
        {recordingState === "paused" && (
          <Button onClick={resumeRecording} color="primary" variant="contained">
            Fortsetzen
          </Button>
        )}
      </div>
    </div>
  );
};

export default AudioRecorderTab;